import { createContext } from "react";

export type TodosContextState = {
  tasks: string[];
  dispatch: (type: string, payload: any) => void;
  user: object;
  dispatchUserAction: (type: string, payload: any) => void;
};
const Context = createContext<TodosContextState|any>(null);

export default Context;
//ciakksdfll