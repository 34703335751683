// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-calcolo-immissioni-di-co-2-tsx": () => import("./../../../src/pages/calcolo-immissioni-di-CO2.tsx" /* webpackChunkName: "component---src-pages-calcolo-immissioni-di-co-2-tsx" */),
  "component---src-pages-d-3-responsive-bar-chart-with-react-and-typescript-tsx": () => import("./../../../src/pages/d3-responsive-bar-chart-with-react-and-typescript.tsx" /* webpackChunkName: "component---src-pages-d-3-responsive-bar-chart-with-react-and-typescript-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-programme-2014-2022-data-hub-tsx": () => import("./../../../src/pages/life-programme-2014-2022-data-hub.tsx" /* webpackChunkName: "component---src-pages-life-programme-2014-2022-data-hub-tsx" */),
  "component---src-pages-mappa-delle-risorse-edibili-abruzzo-tsx": () => import("./../../../src/pages/mappa-delle-risorse-edibili-abruzzo.tsx" /* webpackChunkName: "component---src-pages-mappa-delle-risorse-edibili-abruzzo-tsx" */),
  "component---src-pages-natura-viewer-tsx": () => import("./../../../src/pages/natura-viewer.tsx" /* webpackChunkName: "component---src-pages-natura-viewer-tsx" */),
  "component---src-pages-qualita-dell-aria-nella-mia-citta-tsx": () => import("./../../../src/pages/qualita-dell-aria-nella-mia-citta.tsx" /* webpackChunkName: "component---src-pages-qualita-dell-aria-nella-mia-citta-tsx" */),
  "component---src-pages-shop-prodotti-tipici-abruzzesi-tsx": () => import("./../../../src/pages/shop-prodotti-tipici-abruzzesi.tsx" /* webpackChunkName: "component---src-pages-shop-prodotti-tipici-abruzzesi-tsx" */),
  "component---src-pages-testamento-tsx": () => import("./../../../src/pages/testamento.tsx" /* webpackChunkName: "component---src-pages-testamento-tsx" */),
  "component---src-pages-timeline-tsx": () => import("./../../../src/pages/timeline.tsx" /* webpackChunkName: "component---src-pages-timeline-tsx" */),
  "component---src-pages-todos-permacultura-abruzzo-tsx": () => import("./../../../src/pages/todos-permacultura-abruzzo.tsx" /* webpackChunkName: "component---src-pages-todos-permacultura-abruzzo-tsx" */),
  "component---src-pages-vegetables-tsx": () => import("./../../../src/pages/vegetables.tsx" /* webpackChunkName: "component---src-pages-vegetables-tsx" */),
  "component---src-pages-verifica-numero-di-telefono-tsx": () => import("./../../../src/pages/verifica-numero-di-telefono.tsx" /* webpackChunkName: "component---src-pages-verifica-numero-di-telefono-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-food-tsx": () => import("./../../../src/templates/food.tsx" /* webpackChunkName: "component---src-templates-food-tsx" */),
  "component---src-templates-gather-category-tsx": () => import("./../../../src/templates/gather-category.tsx" /* webpackChunkName: "component---src-templates-gather-category-tsx" */),
  "component---src-templates-item-tsx": () => import("./../../../src/templates/item.tsx" /* webpackChunkName: "component---src-templates-item-tsx" */),
  "component---src-templates-plant-tsx": () => import("./../../../src/templates/plant.tsx" /* webpackChunkName: "component---src-templates-plant-tsx" */)
}

