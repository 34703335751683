import React, { useReducer } from "react";
import Context from "../components/Context";
import reducer from "./taskReducer";
import userReducer from "./userReducer";

const Provider = ({ children }) => {
  const [tasks, dispatch] = useReducer(reducer, []);
  const [user, dispatchUserAction] = useReducer(userReducer, {});

  return (
    <Context.Provider
      value={{
        tasks,
        dispatch,
        user,
        dispatchUserAction,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider