module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/vercel/path0/src/components/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"it_IT","url":"www.casafraine.com","site_name":"Casa Fraine"},"twitter":{"handle":"Vincenzo Marcovecchio","site":"www.casafraine.com","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"casafraine.com","description":"Prodotti che supportano la comunita' ","short_name":"Casa Fraine","start_url":"/","background_color":"#59bb2c","theme_color":"#59bb2c","display":"minimal-ui","icon":"src/assets/images/favicon.png","theme_color_in_head":true,"lang":"it","legacy":true,"icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f7f28d75b318a1ca467f49d43e11ecc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
