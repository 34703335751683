import React, {
  FC,
  ReactNode,
  ReactChildren,
  ReactChild,
  useState,
  useContext,
  useEffect,
} from "react";
import { FaWhatsapp } from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";
import { HiX } from "react-icons/hi";
import { Link } from "gatsby";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { AiTwotoneShopping } from "react-icons/ai";
import Context from "../components/Context";

interface LayoutProps {
  children: ReactNode | ReactNode[];
}

const Layout: FC<LayoutProps | any> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [dato, setDate] = useState<any>([]);
  const apiUrl = `
      https://api.openweathermap.org/data/2.5/onecall?lat=41.8979&lon=14.4898&exclude=hourly,daily&units=metric&appid=22835791d76deb5492e4638d5dd1a34a
  `;

  useEffect(() => {
    fetch(apiUrl)
      .then((data) => data.json())
      .then((res) => setDate(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Context.Consumer>
      {(value) => (
        <>
          <Helmet
            htmlAttributes={{
              lang: "it",
            }}
          >
            <link
              as="style"
              rel=" prefetch stylesheet preload "
              href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300&display=swap"
              type="text/css"
              crossOrigin="anonymous"
            />
            <link
              as="style"
              rel="prefetch stylesheet preload "
              href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
              type="text/css"
              crossOrigin="anonymous"
            />
            <meta property="fb:app_id" content="167048748417291" />
            <link
              as="style"
              rel="prefetch stylesheet preload "
              href="https://fonts.googleapis.com/css2?family=Acme&display=swap"
              type="text/css"
              crossOrigin="anonymous"
            />
              <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
            />

          </Helmet>

          <div
            style={{
              backgroundColor: "whitesmoke",

              textAlign: "center",

              color: "black",
              fontSize: "smaller",
            }}
          >
            Check out my
            <a
              href="https://www.window-swap.com/Window/589702861"
              rel="canonical noopener noreferrer"
              target="_blank"
            >
              &nbsp;windows-swap&nbsp;
            </a>
            🪟
          </div>

          <header
            aria-expanded={isOpen}
            className={isOpen ? "container__open" : "container  "}
          >
            {isOpen ? (
              <HiX className="icon__open" onClick={() => setOpen(!isOpen)} />
            ) : (
              <HiMenuAlt2 className="icon" onClick={() => setOpen(!isOpen)} />
            )}

            <Link className="title" to="/">
              <span>Casa</span>&nbsp;Fraine
            </Link>
            <div className="wea">
              {dato.lat && (
                <img
                  className="cloud"
                  width="80"
                  draggable={false}
                  title={dato.current.weather[0].description}
                  alt={dato.current.weather[0].description}
                  src={`https://openweathermap.org/img/wn/${dato.current.weather[0].icon}@2x.png`}
                  loading="lazy"
                />
              )}
              {dato.lat && <small>{dato.current.feels_like}°C</small>}
            </div>
            <nav>
              <ul className="nav__ul">
                <li>
                  <div className="snipcart-summary snipcart-checkout">
                    <div className="Header__summary__title">
                      <a href="#">
                        <AiTwotoneShopping />
                        <span
                          style={{ color: "black" }}
                          className="snipcart-total-items"
                        ></span>
                      </a>
                    </div>
                  </div>
                </li>
                <li onClick={() => setOpen(false)}>
                  <Link to="/shop-prodotti-tipici-abruzzesi/">Shop</Link>
                </li>
                <li onClick={() => setOpen(false)}>
                  <Link to="/mappa-delle-risorse-edibili-abruzzo/">Mappa</Link>
                </li>
                <li onClick={() => setOpen(false)}>
                  <Link to="/about/">Chi Sono</Link>
                </li>
                <li onClick={() => setOpen(false)}>
                  <Link to="/todos-permacultura-abruzzo">Todo's</Link>
                </li>

                <li>
                  <Link onClick={() => setOpen(false)} replace to="/blog/">
                    Blog
                  </Link>
                </li>
                <Link
                  onClick={() => setOpen(false)}
                  className="login"
                  to="/app"
                >
                  App
                </Link>
              </ul>
            </nav>
          </header>
          <main style={{ minHeight: "100vh" }} className="wrapper">
            {children}
          </main>
          <a
            href="https://wa.me/+353838366935"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer canonical"
            name="whatsup"
          >
            <FaWhatsapp className="fa fa-whatsapp whatsapp-icon"></FaWhatsapp>
          </a>
          <Footer />
        </>
      )}
    </Context.Consumer>
  );
};

export default Layout;

//lolasd