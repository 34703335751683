import React, { useState, useRef, FC, useEffect } from "react";
import { Link } from "gatsby";
import addToMailchimp from "gatsby-plugin-mailchimp";
import itra from "../assets/itra.png";

const Footer: FC = () => {
  const [message, setMessage] = useState("");
  let myRef = useRef();
  const [email, setEmail] = useState("");
  function handleSubmit(event, email) {
    event.preventDefault();
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignor==ing this `then()` altogether)
        setMessage(data.msg);
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  }

  const checkInput = () => {
    if (myRef.current.value === "") {
      setErrorFor();
    } else if (!isEmail(myRef.current.value)) {
      setErrorFor();
    } else {
      setSuccessFor();
    }
  };

  function setErrorFor() {
    const formControl = myRef.current;
    formControl.className = "error shaka";
  }

  function setSuccessFor() {
    const formControl = myRef.current;
    formControl.className = "success";
  }

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <>
      <footer>
        <div className="newsletter">
          <form onSubmit={(event) => handleSubmit(event, email)}>
            <label htmlFor="email">La nostra newsletter</label>
            <input
              value={email}
              name="email"
              placeholder="La tua e-mail"
              type="text"
              spellCheck="false"
              className="input"
              aria-label="email"
              ref={myRef}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="button" onClick={checkInput} type="submit">
              Iscriviti
            </button>{" "}
            {message && (
              <>
                <small>{message.substring(0, 45)}</small>
              </>
            )}
          </form>

          <div className="icons-container">
            <img
              width="60"
              height="60"
              src="https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"
              title="bitcoins accepted"
              alt="bitcoins accepted"
              name="bitcoins "
            />
            <img
              width="70"
              height="70"
              src="https://www.workaway.info/gfx/badges/badge_top_workawayer.png"
              title="top rated workawayer"
              name="badge"
              alt="top rated workawayer"
            />
            <img
              src={itra}
              title="Internationa trail running association"
              alt="Internationa trail running association"
              name="badge"
              width="60"
              height="60"
              style={{
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
          <nav aria-label="footer navigation" expanded="true">
            <ul>
              <li>site</li>
              <li>
                <Link to="/mappa-delle-risorse-edibili-abruzzo">Mappa degli alberi</Link>
              </li>
              <li>
                <Link to="/todos-permacultura-abruzzo">Todo's</Link>
              </li>
              <li>
                <Link to="/eventi-e-manifestazioni-in-abruzzo/" replace>
                  Eventi in Abruzzo
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://vincenzomarcovecchio.github.io/FRAINE-RICICLA/"
                >
                  Waste
                </a>
              </li>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            </ul>
            <ul>
              <li>about</li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer canonical"
                  href="https://it.wikipedia.org/wiki/Fraine"
                >
                  Fraine
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer canonical"
                  href="https://github.com/VincenzoMarcovecchio/casa-fraine"
                >
                  Contribute
                </a>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
             
              <li>
                <Link to="/about">Contact</Link>
              </li>
            </ul>
            <ul>
              <li>Must Know</li>
              <li>
                <a
                  target="_blank"
                  rel="canonical noopener noreferrer"
                  href="https://sinacloud.isprambiente.it/portal/apps/Cascade/index.html?appid=fac2d5b1cc184221aea09012defe2975"
                >
                  EcoAtlante
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="canonical noopener noreferrer"
                  href="https://www.specieinvasive.it/index.php/it/"
                >
                  Specie Invasive
                </a>
              </li>
              <li>
                <Link to="/verifica-numero-di-telefono">Techie Geckie</Link>
              </li>
              <li>
                <Link to="/botanic-api">Botanic API</Link>
              </li>
            </ul>
            <ul>
              <li>Useful Links</li>
              <li>
                <Link to="/life-programme-2014-2022-data-hub/" replace>
                  LIFE
                </Link>
              </li>
              <li>
                <Link to="/natura-viewer/" replace>
                  natura views
                </Link>
              </li>
              <li>
                <Link to="/calcolo-immissioni-di-CO2/" replace>
                  CO2
                </Link>
              </li>
              <li>
                <Link to="/qualita-dell-aria-nella-mia-citta/" replace>
                  Air quality
                </Link>
              </li>
            
            </ul>
          </nav>
        </div>
      </footer>

    </>
  );
};

export default Footer;
